import React, { useState,useEffect } from 'react';
import { ChevronLeft, ChevronRight, Maximize2, List, Tag, Pause, MessageSquare, Globe } from 'lucide-react';
import flag_icon from '../components/images/flag_icon.png';
import flag_2_icon from '../components/images/flag_2_icon.png';
import notifications_icon from '../components/images/notifications_icon.png';
import settings_icon from '../components/images/settings_icon.png';
import account_circle_icon from '../components/images/account_circle_icon.png';
import { Search } from 'lucide-react';
import image1 from '../components/images/Screenshot from 2024-10-31 22-05-14.png';
import { Eye } from 'react-feather';
import { FaRegEye, FaRegListAlt, FaRegFileAlt, FaRegPauseCircle, FaRegCommentDots, FaExpandArrowsAlt } from 'react-icons/fa';
import '@fortawesome/fontawesome-free/css/all.min.css';
import SidebarLeftTestPage from '../components/common/SidebarLeftTestPage';

const COmmunication = () => {
  const [currentQuestion, setCurrentQuestion] = useState(12);
  const totalQuestions = 80;
  const [content, setContent] = useState('AI');

  const question = "Flight Information Region (FIR) is an airspace within which the following services are provided";
  const options = [
  "Question 1",
  "Question 1",
  "Question 1",
  "Question 1",
  "Question 1",
  ];

  const handlePrevious = () => {
    if (currentQuestion > 1) setCurrentQuestion(currentQuestion - 1);
  };

  const handleNext = () => {
    if (currentQuestion < totalQuestions) setCurrentQuestion(currentQuestion + 1);
  };

  const handleContentChange = (newContent) => {
    setContent(newContent);
  };

  const [currentTime, setCurrentTime] = useState('00:00:00');

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(prevTime => {
        const [hours, minutes, seconds] = prevTime.split(':').map(Number);
        
        let newSeconds = seconds + 1;
        let newMinutes = minutes;
        let newHours = hours;

        if (newSeconds === 60) {
          newSeconds = 0;
          newMinutes += 1;
        }
        if (newMinutes === 60) {
          newMinutes = 0;
          newHours += 1;
        }

        return [
          String(newHours).padStart(2, '0'),
          String(newMinutes).padStart(2, '0'),
          String(newSeconds).padStart(2, '0')
        ].join(':');
      });
    }, 1000);

    return () => clearInterval(timerId);
  }, []);



  return (
    <div className="bg-blue-50 grid grid-cols-1 lg:grid-cols-[3fr_1fr] gap-4 w-full h-screen">
      <div className="space-y-4 h-full">
        <div className=" justify-between items-center p-4 ">
        <div className="flex space-x-6">
        <button onClick={handlePrevious} className="text-gray-600">
              <ChevronLeft size={40} />
            </button>
            <span className="text-2xl">Study Report -090- Communications</span>
         </div>
          <div className=" pl-16 space-x-4">
           <p>15 Questions | 8 Novembre 2024 | Time spent: 00:29:11</p>
          </div>
        </div>

        
        <select className="block  ml-16 w-1/5 bg-white  rounded-lg  py-3 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
      <option value="">All</option>
      <option value="option1">Option 1</option>
      <option value="option2">Option 2</option>
    </select>
<div className="space-y-4">
  {options.map((option, index) => (
    <div
      key={index}
      className="w-auto bg-white pt-2 px-4 rounded-lg ml-16 mr-8 pb-2 item-center shadow hover:bg-gray-100 transition-colors"
    >
      <div
        className={`${
          index === 0 || index === 3 ? 'border-red-500' : 'border-green-500'
        } border rounded-lg py-4  items-center space-y-3 pl-3`}
      >
        <span className="mr-2 ">{option}</span>
        {index === 0 || index === 3 ? (
          <div className="space-y-4  space-x-2 pl-2" >
            <p className="">
              <span className="mr-1 bg-red-500 text-white px-2 py-1  ml-2 rounded-full ">x</span> Wrong Answer
            </p>
            <p className="">
              <span className="mr-1 bg-green-500 text-white px-2 py-1 rounded-full  ">v</span> Good Answer
            </p>
                
          </div>
        ) : (
            <p className="">
            <span className="mr-1 bg-green-500 text-white px-2 py-1 rounded-full ">v</span> Good Answer
          </p>
        )}
      </div>
    </div>
  ))}
</div>


</div>

<div className={`bg-gray-100  ml-24 rounded-lg w-full max-w-xs transition-all duration-300 ease-in-out`}>

<div className="flex justify-end space-x-20 pr-7 mb-5">
  {/* Icone de microphone */}
  <button className="text-gray-500 hover:text-gray-700">
    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 12c2.762 0 5-2.238 5-5S14.762 2 12 2 7 4.238 7 7s2.238 5 5 5zm0 1c-2.708 0-7 1.29-7 3.75V18h14v-1.25c0-2.46-4.292-3.75-7-3.75zm0 0v0z" />
    </svg>
  </button>

  {/* Icone de notification */}
  <button className="text-gray-500 hover:text-gray-700">
    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405C18.215 14.215 17 12.21 17 10V8a5 5 0 10-10 0v2c0 2.21-1.215 4.215-3.595 5.595L4 17h5m1 0v1a2 2 0 11-4 0v-1m4 0h0" />
    </svg>
  </button>

  {/* Icone de l'utilisateur */}
  <button className="text-gray-500 hover:text-gray-700">
    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.121 18.364a1 1 0 01-.364-1.121C5.991 14.68 8.5 13 12 13s6.009 1.68 7.243 4.243a1 1 0 01-.364 1.121M12 13a4 4 0 100-8 4 4 0 000 8z" />
    </svg>
  </button>
</div>

<div className='flex'>
<h2 className="text-xl text-gray-900 ml-2 mb-8">Paaim maric :</h2>
<h2  className="text-xl text-green-500 ml-2 mb-8">  75 %</h2>
</div>



<div className="flex">
  <div className="space-y-6 mb-6 pr-8 w-full">
  <div className="w-32 h-32 ml-16 border-8 border-orange-500 rounded-full flex items-center justify-center">
  <p className="text-black">67%</p>
</div>


    <div className="space-y-4 pt-8 space-x-2 pl-2">
      <p>
        <span className="mr-1 bg-red-500 text-white px-2 py-1 ml-2 rounded-full">x</span> Wrong Answer: 5
      </p>
      <p>
        <span className="mr-1 bg-green-500 text-white px-2 py-1 rounded-full">v</span> Good Answer: 10
      </p>
      <p>
        <span className="mr-1 bg-gray-500 text-white px-3 py-1 rounded-full"></span> Not Answered: 0
      </p>
    </div>

    <button 
      className="w-3/4 ml-8 mb-16 bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-700 focus:outline-none mt-6"
    >
      Try again
    </button>

    <div className="space-y-4 pt-16">
      <p className="text-gray-700">Subjects</p>
      <p className="text-gray-700">Other communications</p>
      <p className="text-gray-700">Ground operation</p>
      <p className="text-gray-700">General Operation</p>
      <p className="text-gray-700">Other communications</p>
      <p className="text-gray-700">General Operation</p>
    </div>
  </div>
</div>



</div>

  
 
    </div>
  );
};

export default COmmunication;
