import WrongAnswerIcon from "../images/cross-svgrepo-com.svg";
import CorrectAnswerIcon from "../images/check-alt-svgrepo-com.svg";
const Mark = ({WrongAnswers, CorrectAnswers, NullAnswers}) => {
    return (
        <div className="flex justify-center py-4">
            <div className="w-full">
                <div className="flex justify-center items-center w-full py-2">
                    <div className="bg-red-500 rounded-full w-6 h-6 border-none">
                        <img src={WrongAnswerIcon} alt="wrong answer" />
                    </div>
                    <p className="w-52 text-sm px-4">Wrong Answers: {WrongAnswers}</p>
                </div>
                <div className="flex justify-center items-center w-full py-2">
                    <div className="bg-green-500 rounded-full w-6 h-6 border-none">
                        <img src={CorrectAnswerIcon} alt="correct answer" />
                    </div>
                    <p className="w-52 text-sm px-4">Correct Answers: {CorrectAnswers}</p>
                </div>
                <div className="flex justify-center items-center w-full py-2">
                    <div className="bg-gray-400 rounded-full w-6 h-6 border-none">
                    </div>
                    <p className="w-52 text-sm px-4">No Answers: {NullAnswers}</p>
                </div>
            </div>
        </div >
    )
}

export default Mark;