import { useState, useEffect } from "react";
import ResultCard from "../components/summary/ResultCard";
import ResultNavbar from "../components/summary/ResultNavBar";
import Mark from "../components/summary/Mark";
import { useLocation, useNavigate } from "react-router-dom";
import env from "../env";
import SideProfile from '../components/common/SideProfile';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import timeTrackerFormat from "../utils/timeTrackerFormat";

const SummaryPage = () => {
  const location = useLocation();
  const { slug, quizId, answers, questions, totalQuestions, selectedSubChapters, timeElapsed } = location.state || {};
  const [filter, setFilter] = useState("1"); // État pour gérer la sélection du filtre
  const [filteredQuestions, setFilteredQuestions] = useState(questions);

  const wrongAnswerCount = answers.filter((answer) => !answer.isCorrect).length;
  const correctAnswerCount = answers.filter((answer) => answer.isCorrect).length;
  const noAnswerCount = questions.filter(
    (question) => !answers.some((answer) => answer.questionId === question._id)
  ).length;

  // Nombre de réponses correctes
  const correctAnswersCount = answers.filter((answer) => answer.isCorrect).length;

  // Calcul de la note
  const gradePercentage = (correctAnswersCount / totalQuestions) * 100;

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("id");

  async function handleClick() {
    try {
      const response = await fetch(`${env.API_BASE_URL_PROD}/quiz/save-quiz-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId,
          slug,
          totalQuestions,
          selectedSubChapters,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to save quiz session");
      }

      const data = await response.json();
      navigate(`/quiz/air-law`, {
        state: {
          slug,
          totalQuestions,
          selectedSubChapters,
          sessionId: data.sessionId,
        },
      });
    } catch (error) {
      console.error("Error loading quiz:", error);
    }
  }

  // Fonction pour filtrer les questions selon le filtre sélectionné
  useEffect(() => {
    let newFilteredQuestions;

    switch (filter) {
      case "1": // Toutes les questions
        newFilteredQuestions = questions;
        break;
      case "2": // Réponses correctes
        newFilteredQuestions = questions.filter((question) =>
          answers.some((answer) => answer.questionId === question._id && answer.isCorrect)
        );
        break;
      case "3": // Réponses incorrectes
        newFilteredQuestions = questions.filter((question) =>
          answers.some((answer) => answer.questionId === question._id && !answer.isCorrect)
        );
        break;
      case "4": // Non répondues
        newFilteredQuestions = questions.filter(
          (question) => !answers.some((answer) => answer.questionId === question._id)
        );
        break;
      default:
        newFilteredQuestions = questions;
        break;
    }

    setFilteredQuestions(newFilteredQuestions);
  }, [filter, questions, answers]);

  return (
    <div className="bg-blue-50 min-h-screen flex pt-1">
      <div className="space-y-4 h-full px-20 w-4/5 overflow-y-auto">
        <ResultNavbar
          date={"08 Nov"}
          module={slug}
          numberOfQuestions={totalQuestions}
          reportType={"Study Report"}
          timeSpent={timeTrackerFormat(timeElapsed)}
        />
        <select
          className="block appearance-none w-1/3 bg-white border border-white text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-200"
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value="1">All Questions</option>
          <option value="2">Correct Questions</option>
          <option value="3">Incorrect Questions</option>
          <option value="4">Unanswered Questions</option>
        </select>

        {filteredQuestions.map((question) => {
          const answer = answers.find((ans) => ans.questionId === question._id);
          const noAnswer = !answer;
          const wrongAnswer =
            answer && !answer.isCorrect
              ? question.options.find((opt) => opt._id === answer.answerId)?.text
              : null;
          const correctAnswer = question.options.find((opt) => opt.isCorrect)?.text;

          return (
            <ResultCard
              key={question._id}
              question={question.question}
              isCorrect={answer?.isCorrect}
              wrongAnswer={wrongAnswer}
              correctAnswer={correctAnswer}
              noAnswer={noAnswer}
            />
          );
        })}
      </div>
      <div className="bg-gray-50 pt-10 rounded-lg w-full max-w-lg sm:max-w-md lg:max-w-xs mx-auto py-4 h-screen fixed top-0 right-0 transition-all duration-300 ease-in-out shadow-lg flex-1">
        <div className="p-4 rounded-lg">
          <SideProfile />
          <h2 className="text-xl sm:text-2xl font-light text-gray-900 text-center mt-6 mb-4 pb-5">
            Passing Mark: <span className="text-green-500">75%</span>
          </h2>
          <div className="flex justify-center py-4">
            <Box sx={{ position: "relative", display: "inline-flex" }}>
              <CircularProgress size="120px" variant="determinate" value={gradePercentage} />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h4" component="div" sx={{ color: "text.secondary" }}>
                  {`${Math.round(gradePercentage)}%`}
                </Typography>
              </Box>
            </Box>
          </div>
          <Mark
            CorrectAnswers={correctAnswerCount}
            NullAnswers={noAnswerCount}
            WrongAnswers={wrongAnswerCount}
          />
          <div className="flex justify-center py-4">
            <button
              className="w-full py-2 rounded-lg focus:outline-none shadow-md transition-colors duration-200 mt-6 bg-blue-900 text-white hover:bg-white hover:text-blue-900 px-12 text-lg font-semibold border"
              onClick={handleClick}
            >
              Try again
            </button>
          </div>
          <div className="flex justify-center py-4">Subjects</div>
        </div>
      </div>
    </div>

  );
};

export default SummaryPage;
