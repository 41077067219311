import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import logo from '../components/images/logoflyby.png';


export default function Inscription() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [username, setName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSignUp = async () => {
    setError('');
    setLoading(true);

    try {
      const response = await axios.post('https://api-aviation.technology.dirkk.tech/api/v1/auth/register', {
        username,
        email,
        password,
      });
    
      const { message: responseMessage, username: registeredUsername } = response.data;
    
      toast.success(`${registeredUsername} ${responseMessage}`);
    
      setTimeout(() => {
        navigate('/');
      }, 1000);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="flex flex-col items-center h-screen bg-gray-100 pt-10">
      <div className="rounded-lg shadow-lg w-full max-w-sm mx-auto flex flex-col items-center p-6 sm:p-8 bg-white mt-5">
        <img
          src={logo}
          alt="Logo"
          className="w-30 h-28 sm:w-30 sm:h-30 mb-6 object-contain transition-transform duration-200 hover:scale-105"
        />
        <h1 className="text-gray-800 text-2xl font-semibold mb-4">Sign In</h1>

        <div className="flex flex-col w-full mb-4">
          <button className="bg-blue-800 text-white font-medium py-3 rounded-full mb-2 flex items-center justify-center hover:bg-blue-700 transition-colors duration-200">
            <FontAwesomeIcon icon={faFacebook} className="w-5 h-5 mr-2" />
            Sign In with Facebook
          </button>

          <button className="bg-gray-900 text-white font-medium py-3 rounded-full mb-2 flex items-center justify-center hover:bg-gray-800 transition-colors duration-200">
            <FontAwesomeIcon icon={faGoogle} className="w-5 h-5 mr-2" />
            Sign In with Google
          </button>
        </div>

        <div className="relative my-4 w-full">
          <hr className="border-gray-300" />
          <span className="absolute left-1/2 transform -translate-x-1/2 bg-white px-2 text-gray-500">or</span>
        </div>

        <div className="w-full">
        <input
            type="text"
            placeholder="Name"
            value={username}
            onChange={(e) => setName(e.target.value)}
            className="my-2 p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="my-2 p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="my-2 p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
          />
        </div>

        <button
          className="bg-blue-800 text-white font-semibold py-3 rounded-lg w-full mt-4 hover:bg-blue-700 transition duration-200"
          onClick={handleSignUp}
          disabled={loading}
        >
          {loading ? 'Signing Up...' : 'Sign Up'}
        </button>

        {error && <p className="mt-4 text-red-500">{error}</p>}

        <p className="mt-4 text-gray-700">
          Already an account {' '}
          <Link to="/" className="ml-2 text-blue-800 underline hover:text-blue-600 transition duration-200">
            Sign in
          </Link>
        </p>
      </div>
      {/* afficher les notifications */}
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
  );
}