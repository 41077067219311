import WrongAnswerIcon from "../images/cross-svgrepo-com.svg";
import CorrectAnswerIcon from "../images/check-alt-svgrepo-com.svg";

const ResultCard = ({ question, isCorrect, wrongAnswer, correctAnswer, noAnswer }) => {
    const borderColor = isCorrect
        ? "border-green-400"
        : noAnswer
            ? "border-gray-400"
            : "border-red-400";

    return (
        <div className="flex justify-center items-center p-3 border w-full bg-white rounded-lg min-h-[25vh]">
            <div className={`border rounded-lg w-full ${borderColor}`}>
                <h1 className="p-2">{question}</h1>
                {noAnswer ? (
                    <>
                        <p className="text-gray-500 p-2">No answer selected</p>

                        <div className="flex w-full p-2">
                            <div className="bg-green-500 rounded-full w-6 h-6 border-none">
                                <img src={CorrectAnswerIcon} alt="correct answer" />
                            </div>
                            <p className="w-full px-2">{correctAnswer}</p>
                        </div>
                    </>
                ) : (
                    <>
                        {!isCorrect && wrongAnswer && (
                            <div className="flex w-full p-2">
                                <div className="bg-red-500 rounded-full w-6 h-6 border-none">
                                    <img src={WrongAnswerIcon} alt="wrong answer" />
                                </div>
                                <p className="w-full px-2">{wrongAnswer}</p>
                            </div>
                        )}
                        {correctAnswer && (
                            <div className="flex w-full p-2">
                                <div className="bg-green-500 rounded-full w-6 h-6 border-none">
                                    <img src={CorrectAnswerIcon} alt="correct answer" />
                                </div>
                                <p className="w-full px-2">{correctAnswer}</p>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ResultCard;
