import backButtonIcon from '../images/chevron-back-sharp-svgrepo-com.svg'
import { useNavigate } from 'react-router-dom';

const ResultNavbar = ({ reportType, module, numberOfQuestions, date, timeSpent }) => {
    const navigate = useNavigate();

    const navigatorPage = () => {
        navigate('/question-bank/test')
    }

    return (
        <div className='py-2'>
            <div className="flex justify-between w-[25vw] items-center">
                <button onClick={navigatorPage}>
                    <img src={backButtonIcon} alt="back button" className='w-10' />
                </button>
                <p>{reportType} - {module}</p>
            </div>
            <div className="flex justify-between w-[30vw]">
                <p>{numberOfQuestions} Questions | {date} | Time spent: {timeSpent}</p>
            </div>
        </div>
    )
}
export default ResultNavbar;