const chapters = [
    {
        "topic": "090 - Communications",
        "slug": "090-communications",
        "chapters": [
            {
                "title": "090-01 - Concepts",
                "slug": "090-communications-090-01-concepts",
            },
            {
                "title": "090-02 - General Operating Procedures",
                "slug": "090-communications-090-02-general-operating-procedures",
            },
            {
                "title": "090-03 - Relevant Weather Information",
                "slug": "090-communications-090-03-relevant-weather-information",
            },
            {
                "title": "090-03 - Relevant Weather Information",
                "slug": "090-communications-090-03-relevant-weather-information",
            },
            {
                "title": "090-04 - Voice Communication Failure",
                "slug": "090-communications-090-04-voice-communication-failure",
            },
            {
                "title": "090-05 - Distress and Urgency Procedures",
                "slug": "090-communications-090-05-distress-and-urgency-procedures",
            },
            {
                "title": "090-06 - VHF Propagation and Allocation of Frequencies",
                "slug": "090-communications-090-06-vhf-propagation-and-allocation-of-frequencies",
            },
            {
                "title": "090-07 - Other Communications",
                "slug": "090-communications-090-07-other-communications",
            }
        ],
    },
    {
        "topic": "021 - Airframe, Systems, Electrics, Power Plant",
        "slug": "021-airframe-systems-electrics-power-plant",
        "chapters": [
            {
                "title": "021-01 - System Design, Loads, Stresses, Maintenance",
                "slug": "021-airframe-systems-electrics-power-plant-021-01-system-design-loads-stresses-maintenance",
            },
            {
                "title": "021-02 - Airframe",
                "slug": "021-airframe-systems-electrics-power-plant-021-02-airframe",
            },
            {
                "title": "021-03 - Hydraulics",
                "slug": "021-airframe-systems-electrics-power-plant-021-03-hydraulics",
            },
            {
                "title": "021-04 - Landing Gear, Wheels, Tyres, Brakes",
                "slug": "021-airframe-systems-electrics-power-plant-021-04-landing-gear-wheels-tyres-brakes",
            },
            {
                "title": "021-05 - Flight Controls",
                "slug": "021-airframe-systems-electrics-power-plant-021-05-flight-controls",
            },
            {
                "title": "021-06 - Pneumatics – Pressurisation and Air Conditioning Systems",
                "slug": "021-airframe-systems-electrics-power-plant-021-06-pneumatics-pressurisation-and-air-conditioning-systems",
            },
            {
                "title": "021-07 - Anti-icing and De-icing Systems",
                "slug": "021-airframe-systems-electrics-power-plant-021-07-anti-icing-and-de-icing-systems",
            },
            {
                "title": "021-08 - Fuel System",
                "slug": "021-airframe-systems-electrics-power-plant-021-08-fuel-system",
            },
            {
                "title": "021-09 - Electrics",
                "slug": "021-airframe-systems-electrics-power-plant-021-09-electrics",
            },
            {
                "title": "021-10 - Piston Engines",
                "slug": "021-airframe-systems-electrics-power-plant-021-10-piston-engines",
            },
            {
                "title": "021-11 - Turbine Engines",
                "slug": "021-airframe-systems-electrics-power-plant-021-11-turbine-engines",
            },
            {
                "title": "021-12 - Protection and Detection Systems",
                "slug": "021-airframe-systems-electrics-power-plant-021-12-protection-and-detection-systems",
            },
            {
                "title": "021-13 - Oxygen Systems",
                "slug": "021-airframe-systems-electrics-power-plant-021-13-oxygen-systems",
            }
        ],
    },
    {
        "topic": "010 - Air Law",
        "slug": "010-air-law",
        "chapters": [
            {
                "title": "010-01 - International Law: Conventions, Agreements and Organisations",
                "slug": "010-air-law-010-01-international-law-conventions-agreements-and-organisations",
            },
            {
                "title": "010-02 - Airworthiness of Aircraft, Aircraft Nationality and Registration Marks",
                "slug": "010-air-law-010-02-airworthiness-of-aircraft-aircraft-nationality-and-registration-marks",
            },
            {
                "title": "010-04 - Personnel Licensing",
                "slug": "010-air-law-010-04-personnel-licensing",
            },
            {
                "title": "010-05 - Rules of the Air According to ICAO Annex 2 and SERA",
                "slug": "010-air-law-010-05-rules-of-the-air-according-to-icao-annex-2-and-sera",
            },
            {
                "title": "010-06 - Aircraft Operations",
                "slug": "010-air-law-010-06-aircraft-operations",
            },
            {
                "title": "010-07 - Air Traffic Services (ATS) and Air Traffic Management (ATM)",
                "slug": "010-air-law-010-07-air-traffic-services-ats-and-air-traffic-management-atm",
            },
            {
                "title": "010-08 - Aeronautical Information Service (AIS)",
                "slug": "010-air-law-010-08-aeronautical-information-service-ais",
            },
            {
                "title": "010-09 - Aerodromes",
                "slug": "010-air-law-010-09-aerodromes",
            },
            {
                "title": "010-10 - Facilitation (ICAO Annex 9)",
                "slug": "010-air-law-010-10-facilitation-icao-annex-9",
            },
            {
                "title": "010-11 - Search and Rescue (SAR)",
                "slug": "010-air-law-010-11-search-and-rescue-sar",
            },
            {
                "title": "010-12 - Security – Safeguarding International Civil Aviation against Acts of Unlawful Interference (ICAO Annex 17)",
                "slug": "010-air-law-010-12-security-safeguarding-international-civil-aviation-against-acts-of-unlawful-interference-icao-annex-17",
            },
            {
                "title": "010-13 - Aircraft Accident and Incident Investigation",
                "slug": "010-air-law-010-13-aircraft-accident-and-incident-investigation",
            }
        ],
    },
]

export default chapters;