
import CallCenterIcon from "../images/call-center-assistant-svgrepo-com.svg"
import AvatarFemme from "../images/user_848006.png"
import { Bell } from 'lucide-react';

export default function SideProfile() {
    return (
        <div className="flex justify-between items-center px-4 sm:px-6 pt-4">
                    <button className="text-white px-4 py-2 rounded-lg">
                        <img src={CallCenterIcon} alt="call center" className="w-6" />
                    </button>
                    <button className="text-white px-4 py-2 rounded-lg">
                        <Bell color="#C7C7C7" />
                    </button>
                    <button className="border w-10 h-10 flex justify-center items-center bg-white text-white rounded-full">
                        <img className="w-8 h-8" src={AvatarFemme} alt="next" />
                    </button>
          </div>
    );
} 