import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faBookOpen, faGraduationCap, faPlus, faBuilding } from '@fortawesome/free-solid-svg-icons';

const UpcomingEvents = () => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4 mt-8">
      <h2 className="text-xl font-semibold mb-8">Upcoming</h2>
      <ul className="space-y-2">
        <li className="flex items-center">
          <div className="bg-purple-200 rounded-md p-2 mr-2">
            <FontAwesomeIcon icon={faPlane} className="w-6 h-6 text-purple-600 transform -rotate-90" />
          </div>
          Flight - Today at 13:30
        </li>
        <li className="flex items-center">
          <div className="bg-blue-100 rounded-md p-2 mr-2">
            <FontAwesomeIcon icon={faBuilding} className="w-6 h-6 text-blue-400" />
          </div>
          Ground school - Tomorrow at 8:30
        </li>
        <li className="flex items-center">
          <div className="bg-indigo-200 rounded-md p-2 mr-2">
            <FontAwesomeIcon icon={faPlane} className="w-6 h-6 text-indigo-600 transform -rotate-90" />
          </div>
          Flight - Scheduled on March 15th
        </li>
        <li className="flex items-center">
  <div className="bg-red-200 rounded-md p-2 mr-2">
    <FontAwesomeIcon icon={faPlus} className="text-red-600" style={{ transform: 'scaleX(1.2) scaleY(0.8)', fontSize: '1.8rem' }} />
  </div>
  Medical class 1 - Expires in 37 days
</li>

        <li className="flex items-center">
          <div className="bg-green-200 rounded-md p-2 mr-2">
            <FontAwesomeIcon icon={faGraduationCap} className="w-6 h-6 text-green-600" />
          </div>
          Austro control - Next sitting on March 25th
        </li>
      </ul>
    </div>
  );
};

export default UpcomingEvents;
