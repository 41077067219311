import React from 'react';
import { ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import arrow_circle_left_icon from '../components/images/arrow_circle_left_icon.png'; 

const CoursPage = () => {
    const navigate = useNavigate();
  const subjects = [
    { name: 'Air law', progress: 100, time: '115h' },
    { name: 'AGK', progress: 70, time: '115h' },
    { name: 'Instrumentation', progress: 40, time: '97h' },
    { name: 'Mass & Balance', progress: 100, time: '97h' },
    { name: 'Performance', progress: 18, time: '97h' },
    { name: 'Flight Planning Monitoring', progress: 100, time: '97h' },
    { name: 'Human Performance Li...', progress: 0, time: '97h' },
  ];

  const handleBackClick = () => {
    navigate('/question-bank/test');
  };

  return (
    <div className="bg-gray-100 min-h-screen p-4 flex">
      {/* Left panel */}
      <div className="w-1/3 bg-white shadow-md rounded-lg p-6">
        <h2 className="text-xl font-bold mb-4">Syllabus</h2>
        <button className="bg-gray-100 px-4 py-2 rounded text-gray-600 flex items-center mb-4 focus:outline-none">
          Phase 2 <ChevronRight className="ml-2" />
        </button>
        <ul className="space-y-4">
          {subjects.map((subject, index) => (
            <li key={index} className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <div
                  className={`rounded-full h-10 w-10 flex items-center justify-center text-white font-semibold`}
                  style={{
                    backgroundColor:
                      subject.progress === 100 ? 'green' :
                      subject.progress >= 70 ? 'orange' :
                      subject.progress >= 40 ? 'yellow' : 'gray'
                  }}
                >
                  {subject.progress}%
                </div>
                <div>
                  <h3 className="font-semibold">{subject.name}</h3>
                  <p className="text-sm text-gray-500">Study time: {subject.time}</p>
                </div>
              </div>
              <ChevronRight className="text-gray-400" />
            </li>
          ))}
        </ul>
      </div>

      {/* Right panel */}
      <div className="flex-1 flex flex-col items-center justify-center p-8">
      <button onClick={handleBackClick} className="focus:outline-none">
        <img src={arrow_circle_left_icon} alt="arrow_circle_left_icon" className="w-16 h-16 mb-4" />
      </button>
      </div>
    </div>
  );
};

export default CoursPage;
