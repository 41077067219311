import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import logo from '../components/images/logoflyby.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Connexion() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSignIn = async () => {
    setError('');
    setLoading(true);

    try {
      const response = await axios.post('https://api-aviation.technology.dirkk.tech/api/v1/auth/login', {
        email,
        password,
      });

      const { token, role, username, id} = response.data;

      toast.success(`Hi ${response.data.username}`);

      // Stocker le token dans localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('role', role);
      localStorage.setItem('username', username);
      localStorage.setItem('id', id);
      
      setTimeout(() => {
        //navigate('/homepage');
        navigate('/question-bank/test')
      }, 2000);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
        console.error(err);
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100 pt-5">
      <div className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 rounded-lg border border-red-500 text-black px-6 py-5 mx-4 sm:mx-10 md:mx-20 lg:mx-40 duration-300 text-center">
        <span className="text-lg font-semibold">Restricted Access</span><br></br>
      Access to this site is strictly limited to Flyby students. Registration or login attempts by anyone who is not a Flyby student are strictly prohibited. Any unauthorized access, including bypassing security measures,
      impersonating another user, or using another Flyby student’s account for registration or login, is forbidden. In accordance with Articles 197 bis, 197 ter, 197 quater, and 264 of the Spanish Penal Code, violators may face prison sentences ranging from 6 months to 4 years. Civil action will also be pursued in cases where damages occur.
      </div>

      <div className="rounded-lg shadow-lg w-full max-w-sm mx-auto flex flex-col items-center p-6 sm:p-8 bg-white mt-5">
        <img
          src={logo}
          alt="Logo"
          className="w-30 h-28 sm:w-30 sm:h-30 mb-6 object-contain transition-transform duration-200 hover:scale-105"
        />

        <h1 className="text-gray-800 text-xl sm:text-2xl font-semibold mb-4">Sign In</h1>

        <div className="flex flex-col w-full mb-4">
          <button className="bg-blue-800 text-white font-medium py-2 sm:py-3 rounded-full mb-2 flex items-center justify-center hover:bg-blue-700 transition-colors duration-200">
            <FontAwesomeIcon icon={faFacebook} className="w-4 h-4 sm:w-5 sm:h-5 mr-2" />
            Sign In with Facebook
          </button>

          <button className="bg-gray-900 text-white font-medium py-2 sm:py-3 rounded-full mb-2 flex items-center justify-center hover:bg-gray-800 transition-colors duration-200">
            <FontAwesomeIcon icon={faGoogle} className="w-4 h-4 sm:w-5 sm:h-5 mr-2" />
            Sign In with Google
          </button>
        </div>

        <div className="relative my-4 w-full">
          <hr className="border-gray-300" />
          <span className="absolute left-1/2 transform -translate-x-1/2 bg-white px-2 text-gray-500">or</span>
        </div>

        <div className="w-full">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="my-2 p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="my-2 p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
          />
        </div>

        <button
          className="bg-blue-800 text-white font-semibold py-2 sm:py-3 rounded-lg w-full mt-4 hover:bg-blue-700 transition duration-200"
          onClick={handleSignIn}
          disabled={loading}
        >
          {loading ? 'Signing In...' : 'Sign In'}
        </button>

        {error && <p className="mt-4 text-red-500">{error}</p>}

        <p className="mt-4 text-gray-700 text-sm sm:text-base">
          Create a New account?{' '}
          <Link to="/Inscrire" className="ml-2 text-blue-800 underline hover:text-blue-600 transition duration-200">
            Sign Up
          </Link>
        </p>
      </div>

      {/* Notifications */}
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>

  );
}
