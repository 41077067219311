// src/pages/HomePage.js
import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import Sidebar from "../components/common/Sidebar"; 
import SidebarLeft from "../components/common/SideBarLeft"; 
import font from '../components/images/WhatsApp Image 2024-10-31 at 02.09.19.jpeg';

const gradeData = [
  { name: 'Sem 1', score: 7 },  
  { name: 'Sem 2', score: 5 },
  { name: 'Sem 3', score: 6 },
  { name: 'Sem 4', score: 8 },
  { name: 'Sem 5', score: 7 },
  { name: 'Sem 6', score: 9 },
  { name: 'Sem 7', score: 7 },
];

const learningPathData = [
  { name: 'Section 1', value: 30 },
  { name: 'Section 2', value: 40 }, 
  { name: 'Section 3', value: 30 }, 
];

const HomePage = () => {
  return (
    <div className="flex flex-col lg:flex-row p-6 gap-6">

      <div className="w-64  h-screen fixed top-0 left-0 overflow-auto">
        <Sidebar  />
      </div>

      <div className="flex-1 lg:mx-6 ml-64 lg:ml-0">
        <div className="bg-white shadow-md rounded-lg overflow-hidden mb-6 w-full">
          <img
            src={font}
            alt="New Base"
            className="w-full h-auto object-contain" 
          />
        </div>

        <div className="bg-red-500 shadow-md rounded-lg p-1 mb-6">
          <div className="bg-red-800 text-white rounded-t-lg flex items-center"></div>

          <div className="flex"> 
            <div className="w-1/3 h-24 mr-2 flex justify-between items-center"> 
              <h2 className="text-white font-bold text-3xl">12/February/2024</h2>
            </div>

            <div 
              className="bg-white"
              style={{ 
                clipPath: 'polygon(100% 0%, 100% 100%, 20% 100%, 0% 50%, 20% 0%)',
                width: '90%',
              }}
            >
              <div className="flex justify-between items-center p-4 border-t border-gray-300" style={{ width: '100%' }}>
                <div className="text-red-500 text-3xl font-bold flex flex-col items-start ml-10"> 
                  <div>IMPORTANT</div>
                  <div>ANNONCE</div>
                </div>

                <div className="text-lg pr-10"> 
                  <p className="font-bold">LEBG Flight Schedule:</p>
                  <ul className="list-disc ml-4">
                    <li>Period: From Saturday to Friday</li>
                    <li>Publication date: Friday</li>
                  </ul>
                  <p className="font-bold mt-4">LERJ Flight Schedule:</p>
                  <ul className="list-disc ml-4">
                    <li>Period: From Monday to Sunday</li>
                    <li>Publication date: Sunday</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="bg-white shadow-md rounded-t-lg p-6">
            <h2 className="text-xl font-bold mb-4">Grades For phase2</h2>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={gradeData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis ticks={[2, 4, 6, 8, 10]} />
                <Tooltip />
                <Bar dataKey="score" fill="#4CAF50" barSize={18} radius={[10, 10, 10, 10]} />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-xl font-bold mb-4">Learning Path</h2>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie 
                  data={learningPathData} 
                  cx="50%" 
                  cy="50%" 
                  labelLine={false} 
                  outerRadius={80}
                  dataKey="value"
                >
                  <Cell fill="#FFD700" />
                  <Cell fill="#4CAF50" /> 
                  <Cell fill="#FF4500" />
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
            <div className="flex justify-around mt-4">
              <div className="flex items-center">
                <div className="w-3 h-3 bg-amber-500 rounded-full mr-2"></div>
                <span>Submission</span>
              </div>
              <div className="flex items-center">
                <div className="w-3 h-3 bg-green-500 rounded-full mr-2"></div>
                <span>Research</span>   
              </div>
              <div className="flex items-center">
                <div className="w-3 h-3 bg-red-600 rounded-full mr-2"></div>
                <span>Quiz</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full lg:w-1/4 h-screen sticky top-0 overflow-auto ml-auto">
        <SidebarLeft />
      </div>
    </div>
  );
};

export default HomePage;
