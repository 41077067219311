import React from 'react';

export default function Inscription() {
  return (
    <div className="flex flex-col items-center h-screen bg-gray-100 pt-8" style={{ marginTop: '100px' }}>
      <div className="bg-blue-900 rounded-full max-w-xl w-full mx-auto flex flex-col items-center">
        <div className="flex w-full justify-between p-1">
          <button className="bg-white text-black font-bold h-10 w-64 rounded-full">
            My Profile
          </button>
          <button className="text-white font-bold h-10 w-56 rounded-full mx-auto">
            Delete Account
          </button>
        </div>
      </div>
      <h2 className="mt-4 text-black">You can update your profile on this tab</h2>
      <div className="flex flex-col mt-4 max-w-xl py-3 w-full mx-auto">
        <input
          type="text"
          placeholder="Isaac"
          className="my-2 p-2 border border-blue-500 rounded w-full"
        />
        <input
          type="text"
          placeholder="Lemare"
          className="my-2 p-2 border border-blue-500 rounded w-full"
        />
        <input
          type="email"
          placeholder="Isaac.lamare@gmail.com"
          className="my-2 p-2 border border-blue-500 rounded w-full"
        />
        <div className="flex gap-2 my-2">
          <select className="p-2 border border-blue-500 rounded w-full">
            <option value="spain">Spain</option>
            <option value="france">France</option>
            <option value="germany">Germany</option>
          </select>
          <select className="p-2 border border-blue-500 rounded w-full">
            <option value="spain-value">Spain Value</option>
            <option value="france-value">France Value</option>
            <option value="germany-value">Germany Value</option>
          </select>
        </div>
        <select className="my-2 p-2 border border-blue-500 rounded w-full">
          <option value="spain">Spain</option>
          <option value="france">France</option>
          <option value="germany">Germany</option>
          <option value="italy">Italy</option>
        </select>
        <select className="my-2 p-2 border border-blue-500 rounded w-full">
          <option value="spain AESA">Spain</option>
          <option value="france">France</option>
          <option value="germany">Germany</option>
          <option value="italy">Italy</option>
        </select>
      </div>
      <div className="flex items-center my-2 max-w-xl w-full mx-auto">
        <input type="checkbox" className="mr-2" />
        <p>Accept to receive emails From</p>
      </div>
      <button className="bg-blue-800 text-white font-bold py-2 rounded-lg w-full max-w-xl mt-4 mx-auto">
       update
      </button>
    </div>
  );
}
