import React, { useState, useEffect } from 'react';
import {
  AreaChart, Area, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import SearchQuestion from '../components/common/SearchQuestion.js';


const API_BASE_URL_PROD = "https://api-aviation.technology.dirkk.tech/api/v1/quiz";

const data = [
  { name: 'Jan', Study: 20, Exams: 15 },
  { name: 'Feb', Study: 50, Exams: 26},
  { name: 'Mar', Study: 33, Exams: 50},
  { name: 'Apr', Study: 65, Exams: 23},
  { name: 'May', Study: 40, Exams: 60 },
  { name: 'Jun', Study: 50, Exams: 30 },
  { name: 'Juillet', Study: 25, Exams: 35 },
  { name: 'Juillet', Study: 40, Exams: 50 },

  // Ajoutez plus de points pour encore plus de croisements
];

const TestPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [chapters, setChapters] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [openChapters, setOpenChapters] = useState({});
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [checkedChapters, setCheckedChapters] = useState({});
  const [allChecked, setAllChecked] = useState(true); // État pour le bouton "All"
  const progressValue = 100;
  const [isStudyStarted, setIsStudyStarted] = useState(false);
  const [totalSelectedQuestions, setTotalSelectedQuestions] = useState(0);
  const [numQuestionsToAttempt, setNumQuestionsToAttempt] = useState(0); // Nombre de questions que l'utilisateur souhaite faire
  const navigate = useNavigate();
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [modules, setModules] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
  
    if (!token || isTokenExpired(token)) {
      setIsTokenValid(false);
      navigate("/"); // Rediriger vers la page de login si le token est expiré ou manquant
    }
    calculateTotalSelectedQuestions();
  }, [navigate, checkedChapters]);

  if (!isTokenValid) {
    return null; // On ne rend rien si le token est invalide pour éviter un affichage avant la redirection
  }

  const handleStartQuiz = async () => {
    const slug = selectedTopic;
    const totalQuestions = totalSelectedQuestions;
    
    const selectedSubChapterTitles = Object.keys(checkedChapters)
      .filter((key) => checkedChapters[key] && key.includes('-'))
      .map((key) => {
        const [chapterIndex, subChapterIndex] = key.split('-').map(Number);
        return chapters[chapterIndex]?.sub_chapters[subChapterIndex]?.title;
      })
      .filter(Boolean);
  
    const userId = localStorage.getItem("id");
  
    try {
      const response = await fetch(`${API_BASE_URL_PROD}/save-quiz-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Replace with the user’s token
        },
        body: JSON.stringify({
          userId,
          slug,
          totalQuestions: numQuestionsToAttempt,
          selectedSubChapters: selectedSubChapterTitles,
        }),
      });
  
      if (response.status !== 201) {
        throw new Error("Erreur lors de la récupération des données");
      }
  
      const data = await response.json(); // Convertir la réponse en JSON pour accéder aux données
      navigate(`/quiz/air-law`, {
        state: {
          slug,
          totalQuestions:numQuestionsToAttempt,
          selectedSubChapters: selectedSubChapterTitles,
          sessionId: data.sessionId // Utiliser data.sessionId ici
        },
      });
    } catch (error) {
      console.error("Erreur lors du chargement :", error);
    }
  };
  

  // Fonction pour calculer le total des questions sélectionnées
  const calculateTotalSelectedQuestions = () => {
    let count = 0;
    chapters.forEach((chapter, chapterIndex) => {
      chapter.sub_chapters.forEach((subChapter, subIndex) => {
        if (checkedChapters[`${chapterIndex}-${subIndex}`]) {
          count += subChapter.questionCount;
        }
      });
    });
    setTotalSelectedQuestions(count);
    setNumQuestionsToAttempt(count);
  };
  

  const handleSubjectSelect = (event) => {
    const subject = event.target.value;
    setSelectedTopic(subject);
  };
  const isTokenExpired = (token) => {
    if (!token) return true;
  
    const payload = JSON.parse(atob(token.split(".")[1])); // Décoder la partie payload du JWT
    const expirationTime = payload.exp * 1000; // Convertir en millisecondes
    return Date.now() >= expirationTime; // Vérifier si le token est expiré
  };
  
  /*const handleStudyClick = () => {
    if (!selectedTopic) {
      console.warn("Veuillez sélectionner une matière.");
      return;
    }

    const token = localStorage.getItem("token");
    fetch(`http://127.0.0.1:3002/api/v1/quiz/${selectedTopic}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des données");
        }
        return response.json();
      })
      .then((data) => {
        setChapters(data.chapters || []);
        setTotalQuestions(data.questionCount || 0);

        const initialCheckedState = {};
        data.chapters.forEach((chapter, chapterIndex) => {
          initialCheckedState[chapterIndex] = true;
          chapter.sub_chapters.forEach((_, subIndex) => {
            initialCheckedState[`${chapterIndex}-${subIndex}`] = true;
          });
        });
        setCheckedChapters(initialCheckedState);
        setAllChecked(true); // Initialiser le bouton "All" à coché
      })
      .catch((error) => console.error("Erreur lors du chargement des sujets :", error));
  };*/

  const handleStudyClick = () => {
    if (!selectedTopic) {
      console.warn("Veuillez sélectionner une matière.");
      return;
    }
  
    const token = localStorage.getItem("token");
    fetch(`${API_BASE_URL_PROD}/${selectedTopic}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des données");
        }
        return response.json();
      })
      .then((data) => {
        setChapters(data.chapters || []);
        setTotalQuestions(data.questionCount || 0);
  
        const initialCheckedState = {};
        data.chapters.forEach((chapter, chapterIndex) => {
          initialCheckedState[chapterIndex] = true;
          chapter.sub_chapters.forEach((_, subIndex) => {
            initialCheckedState[`${chapterIndex}-${subIndex}`] = true;
          });
        });
        setCheckedChapters(initialCheckedState);
        setAllChecked(true);
        setIsStudyStarted(true); 

      const initialCheckedCount = countCheckedItems(initialCheckedState);
      setNumQuestionsToAttempt(initialCheckedCount); 
      }).catch((error) => console.error("Erreur lors du chargement des sujets :", error));
  };
  
  const toggleChapter = (index) => {
    setOpenChapters((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleNumQuestionsChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setNumQuestionsToAttempt(value > totalSelectedQuestions ? totalSelectedQuestions : value);
  };
  

  const handleAllCheck = () => {
    const newCheckedState = {};
    chapters.forEach((chapter, chapterIndex) => {
      newCheckedState[chapterIndex] = !allChecked;
      chapter.sub_chapters.forEach((_, subIndex) => {
        newCheckedState[`${chapterIndex}-${subIndex}`] = !allChecked;
      });
    });
    setCheckedChapters(newCheckedState);
    setAllChecked(!allChecked);
    calculateTotalSelectedQuestions();
    const checkedCount = !allChecked ? totalQuestions : 0;
    setTotalSelectedQuestions(checkedCount);
    setNumQuestionsToAttempt(checkedCount);
  };
  

  const handleChapterCheck = (chapterIndex) => {
    const newCheckedChapters = { ...checkedChapters };
    const isChapterChecked = !checkedChapters[chapterIndex];
  
    // Cocher ou décocher tous les sous-chapitres associés
    chapters[chapterIndex].sub_chapters.forEach((_, subIndex) => {
      newCheckedChapters[`${chapterIndex}-${subIndex}`] = isChapterChecked;
    });
    newCheckedChapters[chapterIndex] = isChapterChecked; // Mise à jour du chapitre lui-même
  
    setCheckedChapters(newCheckedChapters);
    calculateTotalSelectedQuestions();
  };
  
  

  const handleSubChapterCheck = (chapterIndex, subChapterIndex) => {
    const newCheckedChapters = { ...checkedChapters };
    newCheckedChapters[`${chapterIndex}-${subChapterIndex}`] = !newCheckedChapters[`${chapterIndex}-${subChapterIndex}`];
    setCheckedChapters(newCheckedChapters);
    calculateTotalSelectedQuestions(); // Mise à jour du total des questions
  };
  

  const countCheckedItems = (checkedChapters) => {
    let count = 0;
  
    // Parcourt les chapitres et sous-chapitres pour compter les éléments cochés
    chapters.forEach((chapter, chapterIndex) => {
      if (checkedChapters[chapterIndex]) {
        count++;
      }
  
      chapter.sub_chapters.forEach((_, subIndex) => {
        if (checkedChapters[`${chapterIndex}-${subIndex}`]) {
          count++;
        }
      });
    });
  
    return count;
  };
  

  return (
    <div className="bg-blue-50 min-h-screen flex">
      <div className="flex-grow">
        <div className="grid grid-cols-1 gap-4 bg-blue-50 max-w-4xl mx-auto">
          <div className="col-span-2 bg-white rounded-lg mt-16 shadow p-6 border-l-0 border-r-0">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-semibold">Score</h2>
              <div className="relative">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="flex items-center text-gray-700 focus:outline-none"
                >
                  7 days
                  <ChevronDown className="ml-2 h-4 w-4" />
                </button>
                {isOpen && (
                  <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      30 days
                    </a>
                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      3 months
                    </a>
                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      1 year
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="h-48 relative">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={data}>
                  <CartesianGrid strokeDasharray="8 8" />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="Study"
                    stroke="blue"
                    strokeWidth={4}
                    fill="#003366"
                    fillOpacity={0.2}
                  />
                  <Area
                    type="monotone"
                    dataKey="Exams"
                    stroke="red"
                    strokeDasharray="5 5"
                    fillOpacity={0}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <h2 className="text-3xl font-bold mb-8 text-center">New Test</h2>
          <div className="flex justify-center space-x-4 mb-8">
            <button onClick={handleStudyClick} className="bg-blue-900 text-white px-12 py-3 rounded-md text-lg font-semibold">
              Study
            </button>
            <button className="bg-blue-900 text-white px-12 py-3 rounded-md text-lg font-semibold">Exams</button>
          </div>

          <div className="relative w-full lg:w-96 mx-auto mb-8">
            <select
              className="block appearance-none w-full bg-white border border-white text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-200"
              onChange={handleSubjectSelect}
            >
              <option value="">Syllabus</option>
              <option value="010-air-law">Air law</option>
              <option value="050-meteorology">Meteorology</option>
              <option value="070-operational-procedures">Operational procedures</option>
              <option value="090-communications">Communication</option>
              <option value="021-airframe-systems-electrics-power-plant">Airframe, Systems, Electrics, Power Plant</option>
              <option value="022-instrumentation">Instrumentation</option>
              <option value="031-mass-balance">Mass & Balance</option>
              <option value="032-performance">Performance</option>
              <option value="033-flight-planning-monitoring">Flight Planning Monitoring</option>
              <option value="040-human-performance-limitations">Human Performance Limitations</option>
              <option value="061-general-navigation">General Navigation</option>
              <option value="062-radio-navigation">Radio Navigation</option>
              <option value="081-principles-of-flight">Principles of Flight</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <ChevronDown className="h-4 w-4" />
            </div>
          </div>

          {selectedTopic && (
            <div className="bg-transparent p-4 rounded-lg mt-4 mx-auto w-full lg:w-5/6">
              {/* Bouton pour "All" */}
              {isStudyStarted && ( <div className="flex items-center justify-between p-3 mb-2 bg-white border border-white rounded-lg">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-blue-600 mr-3"
                  checked={allChecked}
                  onChange={handleAllCheck}
                />
                <span className="text-slate-400 font-normal">
                  All
                </span>
              </div>
              <span className="text-slate-400 font-normal">
                  {totalSelectedQuestions}
                </span>
              </div>)}

              {/* Affichage des chapitres et sous-chapitres avec le total des questions */}
              {chapters.map((chapter, chapterIndex) => (
                <div key={chapterIndex} className="mb-2 bg-white border border-white rounded-lg">
                  <div className="flex items-center justify-between p-3 cursor-pointer" onClick={() => toggleChapter(chapterIndex)}>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-blue-600 mr-3"
                        checked={checkedChapters[chapterIndex] || false}
                        onChange={() => handleChapterCheck(chapterIndex)}
                      />
                      <span className="text-gray-500 font-semibold">{openChapters[chapterIndex] ? <ChevronUp className="p-1 h-6 w-6" /> : <ChevronDown className="p-1 h-6 w-6" /> }</span>
                      <span className="text-slate-400 font-normal">
                        {chapter.title}
                      </span>
                    </div>
                    <span className="text-slate-400 font-normal">
                      {chapter.questionCount}
                    </span>
                    </div>

                  {openChapters[chapterIndex] && (
                    <div className="ml-6 mb-3">
                      {chapter.sub_chapters.map((subChapter, subIndex) => (
                        <div key={subIndex} className="flex items-center justify-between p-2 bg-gray-100 border border-gray-200 rounded-lg mb-1">
                          <div className="flex items-center">
                          <input
                            type="checkbox"
                            className="form-checkbox h-4 w-4 text-blue-600 mr-3"
                            checked={checkedChapters[`${chapterIndex}-${subIndex}`] || false}
                            onChange={() => handleSubChapterCheck(chapterIndex, subIndex)}
                          />
                          <span className="text-slate-400 font-normal">
                            {subChapter.title}
                          </span>
                        </div>
                        <span className="text-slate-400 font-normal">
                            {subChapter.questionCount}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>

        {isStudyStarted && (

            <div className="mt-6 px-8 bg-white mx-auto w-full lg:w-3/4 flex items-center space-x-4 border border-gray-300 rounded-full p-2">
            {/* Input pour le nombre de questions */}
              <div className="pl-1 flex items-center justify-center w-40 h-10 border border-gray-200 rounded-xl">
                <input
                  type="number"
                  value={numQuestionsToAttempt}
                  onChange={handleNumQuestionsChange}
                  className="w-full ml-2 text-center text-blue-900 font-medium outline-none appearance-none"
                  min="1"
                  max={totalSelectedQuestions}
                />
                <span className="ml-2 text-blue-900 font-medium"> / </span>
                <span className="ml-2 mr-3 text-blue-900 font-medium">{totalSelectedQuestions}</span>
              </div>

              {/* Barre de progression */}
              <div className="relative w-full h-1 bg-gray-300 rounded-full overflow-visible">
                <div 
                  className="relative h-full bg-blue-900 rounded-full transition-all duration-300"
                  style={{ width: `${(numQuestionsToAttempt / totalSelectedQuestions) * 100}%` }}
                >
                  <span 
                    className="absolute top-1/2 right-0 w-4 h-4 bg-blue-900 rounded-full transform translate-x-1/2 -translate-y-1/2 transition-all duration-300 shadow-md"
                  ></span>
                </div>
              </div>
            </div>             
        )}


        { isStudyStarted && (
          <div className="mt-6 flex justify-center mb-7">
            <button
              onClick={handleStartQuiz}
              className="px-8 py-2 bg-blue-900 text-white font-semibold rounded-full shadow-md hover:bg-blue-800 transition duration-200 transform hover:scale-105 focus:outline-none focus:ring focus:ring-blue-300"
            >
              Start
            </button>
          </div>
        )}

      </div>
      <SearchQuestion />
    </div>
  );
};

export default TestPage;



