import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "/node_modules/flag-icons/css/flag-icons.min.css";
//import { Topics } from './questiontable';
import { useLocation, useNavigate } from "react-router-dom";
import { Search } from 'lucide-react';
import image1 from '../components/images/ai-imag.png';
import iconAI from '../components/images/icon/ai.png';
import iconList from '../components/images/icon/list.png';
import iconComment from '../components/images/icon/comment.png';
import iconVue from '../components/images/icon/vue.png';
import iconPanJaune from '../components/images/icon/pannejaune.png';
import iconPanRouge from '../components/images/icon/pannerouge.png';
import iconPanVert from '../components/images/icon/pannevert.png';
import iconPolLeft from '../components/images/icon/Polygon-L.png';
import iconPolRight from '../components/images/icon/Polygon-R.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import env from '../env';
import getCountryFlag from '../utils/stringToEmoji';
import SideProfile from '../components/common/SideProfile.js';
import timeTrackerFormat from '../utils/timeTrackerFormat';
import CallCenterIcon from "../components/images/call-center-assistant-svgrepo-com.svg"
import AvatarFemme from "../components/images/femme.png"
import { Bell } from 'lucide-react';


const API_BASE_URL = "http://127.0.0.1:3002/api/v1/quiz";

const API_BASE_URL_PROD = env.API_BASE_URL_PROD;

const QuizPage = () => {
  const [content, setContent] = useState('List');
  const [selectedOption, setSelectedOption] = useState(null);
  const [explanation, setQuizzExplanation] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { slug, selectedSubChapters, totalQuestions, sessionId } = location.state || {};
  const [questions, setQuestions] = useState([]);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("id");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [score, setScore] = useState(0);
  const [isCorrect, setIsCorrect] = useState(null);
  const [timeElapsed, setTimeElapsed] = useState(0); // initial time in seconds (2:57:44)
  const [questionColors, setQuestionColors] = useState({});
  const [answeredQuestions, setAnsweredQuestions] = useState([]);//COuleur de question
  const [isSaving, setIsSaving] = useState(false);
  const [isFinishing, setIsFinishing] = useState(false);
  const [comments, setComments] = useState([]);
  const [selectedButton, setSelectedButton] = useState(null);




  const [comment, setComment] = useState('');

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeElapsed((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await fetch(`${API_BASE_URL_PROD}/quiz/questions/${questions[currentQuestionIndex]._id}/comments`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
        });
        const data = await response.json();
        setComments(data.comments || []);
      } catch (error) {
        console.error('Erreur lors de la récupération des questions:', error);
      }
    };

    fetchComments();
  }, [currentQuestionIndex, token]);  // Retirer `questions` de la dépendance


  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await fetch(`${API_BASE_URL_PROD}/quiz/get-questions`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            slugtopic: slug,
            allsubschapterselected: selectedSubChapters,
            totalQuestions: totalQuestions,
          }),
        });
        const data = await response.json();
        setQuestions(data || []);
      } catch (error) {
        console.error('Erreur lors de la récupération des questions:', error);
      }
    };

    if (slug && selectedSubChapters && totalQuestions) {
      fetchQuestions();
    }
  }, [slug, selectedSubChapters, totalQuestions, token]);  // Vérifiez si ces valeurs ont changé  

  const saveAnswers = async () => {
    setIsSaving(true);
    try {
      const response = await fetch(`${API_BASE_URL_PROD}/quiz/save-answers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId: userId,
          quizId: sessionId,
          answers: Object.keys(answers).map((questionId) => ({
            questionId,
            answerId: answers[questionId].selectedOptionId,
            isCorrect: answers[questionId].isAnswerCorrect,
          })),
          totalQuestions, // Envoyer le nombre total de questions
        }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(`${data.message}`);
        navigate('/question-bank/test')
        //alert(data.message); // Message de succès ou de quiz terminé
      } else {
        toast.error(`${data.message}`);
        //alert('Erreur : ' + data.message);
      }
    } catch (error) {
      console.error('Erreur lors de la sauvegarde des réponses:', error);
      //alert('Erreur lors de la sauvegarde des réponses.');
      toast.error('Erreur lors de la sauvegarde des réponses:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const finishAnswers = async () => {
    setIsFinishing(true);
    try {
      const response = await fetch(`${API_BASE_URL_PROD}/quiz/save-answers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId: userId,
          quizId: sessionId,
          answers: Object.keys(answers).map((questionId) => ({
            questionId,
            answerId: answers[questionId].selectedOptionId,
            isCorrect: answers[questionId].isAnswerCorrect,
          })),
          totalQuestions,
        }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(`${data.message}`);
      } else {
        toast.error(`${data.message}`);
      }
    } catch (error) {
      console.error('Erreur lors de la sauvegarde des réponses:', error);
      toast.error('Erreur lors de la sauvegarde des réponses:', error);
    } finally {
      setIsFinishing(false);
      navigate('/summary',
        {
          state: {
            userId: userId,
            quizId: sessionId,
            selectedSubChapters,
            slug,
            answers: Object.keys(answers).map((questionId) => ({
              questionId,
              answerId: answers[questionId].selectedOptionId,
              isCorrect: answers[questionId].isAnswerCorrect,
            })),
            questions: questions,
            totalQuestions,
            timeElapsed,
          }
        }
      );
    }
  };

  const submitAnswer = async (selectedOptionId) => {
    const currentQuestion = questions[currentQuestionIndex];
    const correctOption = currentQuestion.options.find(option => option.isCorrect);

    // If the question has already been answered, prevent re-answering
    if (answers[currentQuestion._id] !== undefined) return;

    setSelectedOption(selectedOptionId);
    const isAnswerCorrect = correctOption._id === selectedOptionId;
    setIsCorrect(isAnswerCorrect);

    // Store the answer and correctness for the current question
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [currentQuestion._id]: {
        selectedOptionId,
        isAnswerCorrect
      }
    }));

    // Update score and question colors
    const newColor = isAnswerCorrect ? "bg-green-200" : "bg-red-200";
    setQuestionColors(prevColors => ({
      ...prevColors,
      [currentQuestionIndex]: newColor,
    }));

    if (!answeredQuestions.includes(currentQuestionIndex)) {
      setAnsweredQuestions([...answeredQuestions, currentQuestionIndex]);
    }

    if (isAnswerCorrect) {
      setScore(prevScore => prevScore + 1);
      setTimeout(() => {
        handleNext();
      }, 1000);
    } else {
      setTimeout(() => { }, 2000);
    }
  };

  const sendComment = async () => {
    if (!comment) {
      toast.error('Veuillez saisir un commentaire');
      return; // Empêche l'exécution du reste de la fonction
    }

    try {
      const response = await fetch(`${API_BASE_URL_PROD}/quiz/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          responseSessionId: sessionId,
          questionId: questions[currentQuestionIndex]?._id, // Utilisation de l'opérateur ?. pour éviter les erreurs
          userId,
          content: comment,
        }),
      });
      const data = await response.json();
            setComments([...comments, data.comment]);
      if (!response.ok) {
        // En cas d'erreur côté serveur, afficher un message clair
        const errorMessage = data.message || 'Une erreur est survenue lors de l\'envoi du commentaire.';
        toast.error(errorMessage);
        return;
      }
      // Affichage du succès si tout se passe bien
      toast.success(data.message || 'Commentaire envoyé avec succès !');
    } catch (error) {
      // Gestion des erreurs réseau ou autres exceptions
      console.error('Erreur lors de l\'envoi du commentaire :', error);
      toast.error('Une erreur réseau est survenue. Veuillez réessayer plus tard.');
    }
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      const nextIndex = currentQuestionIndex + 1;
      setCurrentQuestionIndex(nextIndex);

      const nextQuestionId = questions[nextIndex]._id;
      const previousAnswer = answers[nextQuestionId];
      if (previousAnswer) {
        setSelectedOption(previousAnswer.selectedOptionId);
        setIsCorrect(previousAnswer.isAnswerCorrect);
      } else {
        setSelectedOption(null);
        setIsCorrect(null);
      }
    } else {
      // alert(`Quiz terminé ! Votre score est ${score} sur ${questions.length}`);
    }
    setQuizzExplanation('');
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      const prevIndex = currentQuestionIndex - 1;
      setCurrentQuestionIndex(prevIndex);

      const prevQuestionId = questions[prevIndex]._id;
      const previousAnswer = answers[prevQuestionId];
      if (previousAnswer) {
        setSelectedOption(previousAnswer.selectedOptionId);
        setIsCorrect(previousAnswer.isAnswerCorrect);
      } else {
        setSelectedOption(null);
        setIsCorrect(null);
      }
    }
    setQuizzExplanation('');
  };

  useEffect(() => {

    if (!token || isTokenExpired(token)) {
      setIsTokenValid(false);
      navigate("/");
    }
  }, [navigate, token]);

  if (!isTokenValid) {
    return null;
  }

  const isTokenExpired = (token) => {
    if (!token) return true;
    const payload = JSON.parse(atob(token.split(".")[1]));
    const expirationTime = payload.exp * 1000;
    return Date.now() >= expirationTime;
  };


  const formatTime = () => {
    const hours = String(Math.floor(timeElapsed / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((timeElapsed % 3600) / 60)).padStart(2, '0');
    const seconds = String(timeElapsed % 60).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };


  const handleContentChange = (newContent) => {
    setContent(newContent);
  };

  /*const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };*/

  return (
    <div className="bg-blue-50 min-h-screen flex">
      <div className="flex-grow pt-5">
        <div className="flex justify-between items-center p-4">
          <div className="flex items-center space-x-6 ml-10 pl-20">
            <button className="text-green-400">
              <img src={iconPanVert} alt="icon panne vert" className="size-7" />
            </button>
            <button className="text-yellow-500">
              <img src={iconPanJaune} alt="icon panne jaune" className="size-7" />
            </button>
            <button className="text-red-500">
              <img src={iconPanRouge} alt="icon panne red" className="size-7" />
            </button>
          </div>
          <div className="flex items-center space-x-6">
            <button onClick={handlePrevious} className="text-blue-800">
              <img src={iconPolLeft} alt="icon polygone left" className="size-4" />
            </button>
            <span className="font-normal text-xl">{currentQuestionIndex + 1} / {totalQuestions}</span>
            <button onClick={handleNext} className="text-blue-800">
              <img src={iconPolRight} alt="icon polygone right" className="size-4" />
            </button>
          </div>
          <p className='text-lg'>{`Ref: ${slug}`}</p>
        </div>

        {questions.length > 0 ? (
          <div className="p-10">
            <h2 className="text-xl mb-8">{questions[currentQuestionIndex].question}</h2>
            <div className="space-y-4">

              {questions[currentQuestionIndex].options.map((option, index) => {
                const isSelected = selectedOption === option._id;
                const isAlreadyAnswered = answers[questions[currentQuestionIndex]._id] !== undefined;
                const wasAnswerCorrect = answers[questions[currentQuestionIndex]._id]?.isAnswerCorrect;

                return (
                  <div
                    key={option._id}
                    onClick={() => !isAlreadyAnswered && submitAnswer(option._id)}
                    className={`bg-white py-6 px-4 rounded-full cursor-pointer shadow hover:bg-gray-100 transition-colors ${isSelected
                      ? isCorrect === null
                        ? 'text-blue-500'
                        : isCorrect
                          ? 'text-green-500 border-green-500'
                          : 'text-red-500 border-red-500'
                      : 'text-gray-800'
                      } ${!isSelected && wasAnswerCorrect === false && option.isCorrect
                        ? 'text-green-500 border-green-500'
                        : ''
                      } ${isAlreadyAnswered ? 'cursor-not-allowed ' : ''}`}
                  >
                    <span
                      className={`inline-block w-8 h-8 rounded-full pt-1 mr-4 text-center border ${isSelected
                        ? isCorrect === null
                          ? 'text-blue-500'
                          : isCorrect
                            ? 'text-green-500 border-green-500'
                            : 'text-red-500 border-red-500'
                        : 'text-gray-800'
                        }`}
                    >
                      {String.fromCharCode(65 + index)}
                    </span>
                    {option.text}
                  </div>
                );
              })}

            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-screen">
            <div className="relative flex items-center justify-center w-16 h-16">
              <div className="w-12 h-12 border-4 border-blue-500 border-solid rounded-full animate-spin border-t-transparent"></div>
            </div>
            <p className="text-gray-500 text-lg font-semibold mt-4">Loading...</p>
          </div>
        )}

        <div className="fixed bottom-10 left-0 right-0 p-4 flex justify-between items-center max-w-[70%] ml-20 bg-blue-50 rounded-xl">
          <button className={`font-meduim py-3 rounded px-4 text-black border border-blue-100 ${
              selectedButton === 'Explanation' ? 'bg-white' : ''
            }`} onClick={() => {
              setSelectedButton('Explanation');
            // setQuizzExplanation(questions[currentQuestionIndex].explanation)
            handleContentChange('Explanation')
          }}>
            Explanation
          </button>
          <div className="flex items-center text-blue-900 space-x-4 text-4xl font-light">
            <span>{timeTrackerFormat(timeElapsed)}</span>
          </div>
          <div className="flex space-x-9">
            <button
              onClick={() => {
                setSelectedButton('List');
                handleContentChange('List');
              }}
              className={`p-2 rounded ${
                selectedButton === 'List' ? 'bg-white' : ''
              }`}
            >
              <img src={iconList} alt="icon List" className="text-blue-900 size-7" />
            </button>
            <button
              onClick={() => {
                setSelectedButton('AI');
                handleContentChange('AI');
              }}
              className={`p-2 rounded ${
                selectedButton === 'AI' ? 'bg-white' : ''
              }`}
            >
              <img src={iconAI} alt="icon AI" className="text-blue-900 size-7" />
            </button>
            <button
              onClick={() => {
                setSelectedButton('MessageSquare');
                handleContentChange('MessageSquare');
              }}
              className={`p-2 rounded ${
                selectedButton === 'MessageSquare' ? 'bg-white' : ''
              }`}
            >
              <img src={iconComment} alt="icon Comment" className="text-blue-900 size-7" />
            </button>
            <button
              onClick={() => {
                setSelectedButton('Eye');
                handleContentChange('Eye');
              }}
              className={`p-2 rounded ${
                selectedButton === 'Eye' ? 'bg-white' : ''
              }`}
            >
              <img src={iconVue} alt="icon vue" className="text-blue-900 size-7" />
            </button>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 rounded-lg w-full max-w-lg sm:max-w-md lg:max-w-xs mx-auto transition-all duration-300 ease-in-out shadow-lg overflow-y-auto max-h-screen">
          <SideProfile />
          <div className="p-4 rounded-lg h-full">
            
            {content === 'List' && (
              <div className="flex flex-col h-full">
              <h2 className="text-xl sm:text-2xl font-light text-gray-900 text-center mt-6 mb-4 pb-5">
                List Content
              </h2>
            
              <div className="flex-grow overflow-y-auto px-4">
                <div className="grid grid-cols-5 gap-2">
                  {Array.from({ length: totalQuestions }, (_, i) => {
                    const number = i + 1;
                    const hasAnswered = answeredQuestions.includes(i);
                    const isCurrentQuestion = i === currentQuestionIndex;
            
                    let bgColor = "bg-white";
                    if (hasAnswered) {
                      bgColor = answers[questions[i]._id]?.isAnswerCorrect
                        ? "bg-green-200"
                        : "bg-red-200";
                    }
                    if (isCurrentQuestion) {
                      bgColor =
                        selectedOption !== null
                          ? isCorrect
                            ? "bg-green-200"
                            : "bg-red-200"
                          : bgColor;
                    }
            
                    return (
                      <span
                        key={i}
                        className={`${bgColor} text-black text-center py-2 px-5 rounded shadow`}
                      >
                        {number}
                      </span>
                    );
                  })}
                </div>
              </div>
            
              <div className="sticky bottom-0 bg-white shadow-lg p-4">
                <button
                  className="w-full py-2 rounded-lg focus:outline-none shadow-md transition-colors duration-200 mt-2 bg-blue-900 text-white hover:bg-white hover:text-blue-900 px-12 text-lg font-semibold border"
                  onClick={finishAnswers}
                  disabled={isFinishing}
                >
                  {isFinishing ? (
                    <span className="flex items-center">
                      <svg
                        className="animate-spin h-5 w-5 text-white mr-3"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l2.293-2.292-1.414-1.414L4 15.292V20h4v-2h-2.586z"
                        ></path>
                      </svg>
                      End...
                    </span>
                  ) : (
                    "Finish"
                  )}
                </button>
            
                <button
                  className="w-full bg-gray-400 text-white py-2 rounded-lg hover:bg-blue-900 focus:outline-none shadow-md transition-colors duration-200 mt-4 px-12 text-lg font-semibold"
                  onClick={saveAnswers}
                  disabled={isSaving}
                >
                  {isSaving ? (
                    <span className="flex items-center">
                      <svg
                        className="animate-spin h-5 w-5 text-white mr-3"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l2.293-2.292-1.414-1.414L4 15.292V20h4v-2h-2.586z"
                        ></path>
                      </svg>
                      Save...
                    </span>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
            )}
            {content === 'AI' && (
              <>
                <div className="flex flex-col items-center mb-4">
                  <div className="relative w-full">
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                      <Search size={20} />
                    </span>
                    <input type="text" placeholder="Ask anything" className="w-full p-2 border rounded-lg pl-10" />
                  </div>
                  <h2 className="text-xl sm:text-2xl font-medium text-gray-900 text-center mt-6 mb-4 pb-5">
                    AI
                  </h2>
                  <div className="w-24 h-24 bg-blue-200 rounded-full mt-4 flex items-center justify-center">
                    <img src={image1} alt="AI Icon" className="w-24 h-24 rounded-full" />
                  </div>
                </div>
              </>
            )}
            {content === 'Tag' && (
              <div>
                <h3 className="font-semibold">Tag Content</h3>
                <p>Information related to tagging.</p>
              </div>
            )}
            {content === 'Pause' && (
              <div>
                <h3 className="font-semibold">Similar Questions</h3>
              </div>
            )}
            {content === 'Explanation' && (
              <div>
                <h2 className="text-xl sm:text-2xl font-light text-gray-900 text-center mt-6 mb-4 pb-5">
                  Explanation Content
                </h2>
                {
                  questions?.filter(question => question._id === questions[currentQuestionIndex]._id)
                    .map((question, index) => {
                      return (
                        <div className="mt-6">
                          <h3 className="font-semibold mb-2">Answer</h3>
                          <p key={index}>{question.explanation}</p>
                        </div>
                      )
                    })
                }
              </div>
            )}
            {content === 'Eye' && (
              <div>
                <h2 className="text-xl sm:text-2xl font-light text-gray-900 text-center mt-6 mb-4 pb-5">
                  Seen in Austro
                </h2>
                <div className="grid grid-cols-5 gap-2">
                  {
                    questions?.filter(question => question._id === questions[currentQuestionIndex]._id)
                      .map(question =>
                        question.countries?.map((country, index) => {
                          return (
                            <span key={index} className={`fi fi-${getCountryFlag(country)}`}></span>
                          )
                        })
                      )
                  }
                </div>
              </div>
            )}
            {content === 'MessageSquare' && (
              <div>
                <h2 className="text-xl sm:text-2xl font-light text-gray-900 text-center mt-6 mb-4 pb-5">
                  Comments
                </h2>
                <div className="">
                  {
                    comments && comments.map(
                      (comment, index) => {
                        return (
                          <div key={index} className='w-full border border-slate-300 my-3 py-2 px-2 rounded-lg'>
                            {comment.content}
                            <div className='flex justify-end text-sm text-slate-300'>
                              {comment.createdAt}
                            </div>
                          </div>)
                      }
                    )
                  }
                  <div>
                    <textarea className='w-full border border-slate-300 my-3 py-2 px-2 rounded-lg' onChange={(e) => setComment(e.target.value)}>
                    </textarea>
                    <div className='flex justify-end'>
                      <button className='w-full bg-gray-400 text-white py-1 rounded-lg hover:bg-blue-900 focus:outline-none shadow-md transition-colors duration-200 mt-6bg-gray-400  px-12 text-lg font-semibold mb-7' onClick={sendComment}>
                        Send
                      </button>
                      <br></br>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
      </div>
      {/*afficher les notifications */}
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
  );
};

export default QuizPage;