import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logoImage from '../images/logo-white.png';
import titleImage from '../images/logofly.png';

const Sidebar = () => {
  const [showText, setShowText] = useState(false);
  const [activeLink, setActiveLink] = useState("/");
  const [showPopup, setShowPopup] = useState(false); 
  const [popupMessage, setPopupMessage] = useState(""); 
  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  const handleMouseEnter = () => {
    setShowText(true);
  };

  const handleMouseLeave = () => {
    setShowText(false);
  };

  const handleLinkClick = (to, label) => {
    if (to !== "/question-bank/test") {
      setPopupMessage(`The page "${label}" is under construction.`);
      setShowPopup(true); 
    } else {
      setActiveLink(to); 
    }
  };

  const closePopup = () => {
    setShowPopup(false); 
  };

  return (
    <>
      <nav
        className={`bg-blue-900 ${showText ? 'w-64' : 'w-20'} text-white py-4 h-screen fixed top-0 left-0 transition-all duration-400 overflow-hidden`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="text-center mb-8 flex flex-col items-center pr-4 justify-center">
          {showText ? (
            <img src={titleImage} alt="Titre" className="h-12 w-auto mb-2" />
          ) : (
            <img src={logoImage} alt="Logo" className="h-14 w-14 ml-1" />
          )}
        </div>
        <ul className="space-y-4">
          {[
            { to: "/", icon: "dashboard", label: "Dashboard" },
            { to: "/courses", icon: "book", label: "Courses" },
            { to: "/grades", icon: "school", label: "Grades" },
            { to: "/question-bank/test", icon: "quiz", label: "Question Bank" },
            { to: "/aviation-news", icon: "flight_takeoff", label: "Aviation News" },
            { to: "/flights", icon: "flight", label: "Flights" },
            { to: "/agenda", icon: "event", label: "Agenda" },
            { to: "/customer-service", icon: "support_agent", label: "Customer Service" },
            { to: "/messages", icon: "message", label: "Messages" },
            { to: "/call-meeting", icon: "call", label: "Call Meeting" },
          ].map(({ to, icon, label }) => (
            <li 
              key={label} 
              className={`pl-0 pr-0 py-2 hover:bg-blue-700 flex justify-center ${activeLink === to ? 'bg-white bg-opacity-20 border-l-4 border-white border-opacity-70 rounded-none' : ''}`}
            >
              <button
                onClick={() => handleLinkClick(to, label)}
                className="flex pl-5 w-full text-left"
              >
                <span 
                  className="material-icons"
                  style={{ 
                    fontSize: '2rem', 
                    color: activeLink === to ? 'white' : 'rgba(255, 255, 255, 0.5)',
                    marginLeft: showText ? '0.5rem' : '0',
                  }}
                >
                  {icon}
                </span>
                {showText && <span className="ml-2 text-lg">{label}</span>}
              </button>
            </li>
          ))}
        </ul>
      </nav>

      {showPopup && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={closePopup}
        >
          <div
            className="bg-blue-900 rounded-lg p-6 shadow-lg w-80 text-center relative"
            onClick={(e) => e.stopPropagation()} 
          >
            <img src={titleImage} alt="Logo" className="w-44 ml-8" />
            <h2 className="text-xl font-bold mb-4 ">Coming Soon</h2>
            <p className="text-white mb-6">{popupMessage}</p>
            <button
              onClick={closePopup}
              className="bg-white text-black hover:text-white border border-white px-4 py-2 rounded hover:bg-blue-900 transition"
            >
              OK
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
