import React, { useState } from 'react';

const TestPages = () => {
  const [msg, setMsg] = useState();

  const handleClick = async () => {
    setMsg('')
    try {
      const response = await fetch('/api');  // Attente de la réponse du serveur
      if (!response.ok) {
        throw new Error('Network response was not ok');  // Gérer les erreurs de réseau
      }
      const dat = await response.json();  // Attente de la conversion en JSON
      console.log(dat);
      setMsg(dat.message);  // Mise à jour de l'état avec les données reçues
    } catch (error) {
      console.error('There was an error!', error);  // Gestion des erreurs
      setMsg('Erreur lors de la récupération des données');
    }
  };

  const handleClick1= async () => {
    setMsg('')

  };
  return (
    <div>
      <h1>{msg}</h1>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <button onClick={handleClick}>Récupérer</button>
      <button onClick={handleClick1}>Réintialiser</button>
    </div>
  );
};

export default TestPages;
