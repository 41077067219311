import React, { useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';

export default function SidebarLeftTestPage() {
    const [showRoseDiv, setShowRoseDiv] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [score] = useState(82);
    const navigate = useNavigate();
    const [isOperationSelected, setIsOperationSelected] = useState(false);
   
    const [isContainerExpanded, setIsContainerExpanded] = useState(false); 
  
    const handleSubjectSelect = (event) => {
      const subject = event.target.value;
      setIsOperationSelected(subject === 'operation');
      if (subject && subject !== 'operation') {
        navigate(`/quiz/${subject}`);
      }
    };
  
    const progressValue = 100;
    const handleMouseEnter = () => {
      setShowText(true);
    };
    const [searchContainerWidth, setSearchContainerWidth] = useState('w-0'); 
    const [showText, setShowText] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
  
    const handleSearchClick = () => {
      // Gérer l'option "Operation"
      if (selectedOption === "option1") {
        setSearchContainerWidth(isContainerExpanded ? 'w-0' : 'w-3/4');
        setIsContainerExpanded(!isContainerExpanded);
        setShowText(!showText);
      }
    
      // Gérer l'option "Meteorology"
      if (selectedOption === "option2") {
        setShowRoseDiv(true); // Afficher le div rose
        setSearchContainerWidth(isContainerExpanded ? 'w-0' : 'w-3/4');
        setIsContainerExpanded(!isContainerExpanded);
        setShowText(!showText);
      }
    };
    
    const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 3; // Nombre total de pages
  
  const handlePageChange = (direction) => {
    if (direction === 'next' && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (direction === 'previous' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <div>
      <div className={`bg-gray-100 pt-10 rounded-lg w-full max-w-xs transition-all duration-300 ease-in-out`}>

<div className="flex justify-end space-x-6 pr-4 mb-5">
  {/* Icone de microphone */}
  <button className="text-gray-500 hover:text-gray-700">
    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 12c2.762 0 5-2.238 5-5S14.762 2 12 2 7 4.238 7 7s2.238 5 5 5zm0 1c-2.708 0-7 1.29-7 3.75V18h14v-1.25c0-2.46-4.292-3.75-7-3.75zm0 0v0z" />
    </svg>
  </button>

  {/* Icone de notification */}
  <button className="text-gray-500 hover:text-gray-700">
    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405C18.215 14.215 17 12.21 17 10V8a5 5 0 10-10 0v2c0 2.21-1.215 4.215-3.595 5.595L4 17h5m1 0v1a2 2 0 11-4 0v-1m4 0h0" />
    </svg>
  </button>

  {/* Icone de l'utilisateur */}
  <button className="text-gray-500 hover:text-gray-700">
    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.121 18.364a1 1 0 01-.364-1.121C5.991 14.68 8.5 13 12 13s6.009 1.68 7.243 4.243a1 1 0 01-.364 1.121M12 13a4 4 0 100-8 4 4 0 000 8z" />
    </svg>
  </button>
</div>

<h2 className="text-2xl text-gray-900 text-center mb-8">Search Question</h2>

<div className="flex">
  <div className="space-y-6 mb-6 pr-8 w-full">
    <select
      className="block w-full bg-white border border-gray-300 rounded py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
      onChange={(e) => setSelectedOption(e.target.value)}
    >
      <option value="">Syllabus</option>
      <option value="option1">Operation</option>
      <option value="option2">Meteorology</option>
    </select>
    
    <select className="block w-full bg-white border border-gray-300 rounded py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
      <option value="">Subject 1</option>
      <option value="option1">Option 1</option>
      <option value="option2">Option 2</option>
    </select>

    <select className="block w-full bg-white border border-gray-300 rounded py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
      <option value="">Answer</option>
      <option value="option1">Option 1</option>
      <option value="option2">Option 2</option>
    </select>

    <div className="flex items-center border border-gray-300 rounded w-full mt-4">
      <button className="px-3 text-gray-500">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-4.35-4.35M16.95 11A6 6 0 1 0 5 11a6 6 0 0 0 11.95 0z" />
        </svg>
      </button>
      <input 
        type="text" 
        placeholder="Search..." 
        className="w-full px-3 py-2 focus:outline-none"
      />
    </div>

    <button 
      onClick={handleSearchClick}
      className="w-3/4 ml-8 mb-16 bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-700 focus:outline-none mt-6"
    >
      Search
    </button>

    <h3 className="text-2xl text-gray-900 text-center mt-16">Last Saved test</h3>

    <div className="space-y-4 mt-6">
      <p className="text-gray-700">Air Law 5/10 - 22:53</p>
      <p className="text-gray-700">Meteorology 13/01 - 13:10</p>
      <p className="text-gray-700">Communication 08/01 - 08:05</p>
      <p className="text-gray-700">Air Law 11/01 - 16:37</p>
    </div>
    
    <button 
      className="w-3/4 ml-8 mb-16 bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-700 focus:outline-none mt-6"
    >
      More
    </button>
  </div>
</div>

<div className={`bg-gray-100 ${searchContainerWidth} mr-80 text-white pt-2 fixed top-20 rounded-lg bottom-8 right-0 transition-all duration-300`}>
  <div className="flex">
    {showText && selectedOption === 'option1' && (
      <div className="flex flex-col justify-between mx-16 w-full">
        <div className="flex justify-between items-center mb-10">
          <button onClick={handleSearchClick} className="bg-blue-900 mb-5 text-white font-bold py-3 w-12 rounded-full">x</button>
          <p className="text-gray-500 text-2xl text-center">Searched question(s)</p>
          <button className="bg-white text-black text-2xl py-10 rounded-lg w-1/6 mt-2">Résultat 7</button>
        </div>

        <button className="bg-white text-black py-5 rounded-lg mb-3 w-full">question 1</button>
        <button className="bg-white text-black py-5 rounded-lg mb-3 w-full">question 2</button>
        <button className="bg-white text-black py-5 rounded-lg mb-3 w-full">question 3</button>
        <button className="bg-white text-black py-5 rounded-lg mb-3 w-full">question 5</button>
        <button className="bg-white text-black py-5 rounded-lg w-full">question 6</button>
        <button className="bg-white text-black py-5 mt-4 rounded-lg w-full">question 7</button>
      </div>
    )}

    {showText && selectedOption === 'option2' && (
      <div className="flex flex-col justify-between mx-16 w-full">
        <div className="flex justify-between items-center mb-2">
          <button onClick={handleSearchClick} className="bg-blue-900 mb-5 text-white font-bold py-3 w-12 rounded-full">x</button>
          <p className="text-gray-500 text-2xl mr-80">Last saved tests</p>
        </div>

        <button className="bg-white text-black py-3 px-0 rounded-lg mb-3 w-full">Air low-13 Jnavier 2025 - 13:40-MIxed -243 questions</button>
        <button className="bg-white text-black py-3 px-0 rounded-lg mb-3 w-full">Air low-24 July Matlan test to max - 200 questions</button>
     <button className="bg-white text-black py-3 px-0 rounded-lg mb-3 w-full">Air low-13 Jnavier 2025 - 13:40-MIxed -243 questions</button>
<button className="bg-white text-black py-3 px-0 rounded-lg w-full">Air low-13 Jnavier 2025 - 13:40-MIxed -243 questions</button>
<button className="bg-white text-black py-3 px-0 mt-3 rounded-lg mb-3 w-full">Air low-13 Jnavier 2025 - 13:40-MIxed -243 questions</button>
<button className="bg-white text-black py-3 px-0 w-full">Air low-13 Jnavier 2025 - 13:40-MIxed -243 questions</button>
<button className="bg-white text-black py-3 px-0 mt-4 rounded-lg w-full">Air low-13 Jnavier 2025 - 13:40-MIxed -243 questions</button>
<button className="bg-white text-black py-3 px-0 mb-3 mt-3 w-full">Air low-13 Jnavier 2025 - 13:40-MIxed -243 questions</button>
<button className="bg-white text-black py-3 px-0 rounded-lg w-full">Air low-13 Jnavier 2025 - 13:40-MIxed -243 questions</button>
<button className="bg-white text-black py-3 px-0 mt-4 rounded-lg w-full">Air low-13 Jnavier 2025 - 13:40-MIxed -243 questions</button>

<div className="flex justify-center items-center mt-5">
<button 
onClick={() => handlePageChange('previous')}
className="bg-blue-900 text-white px-4 py-2 rounded-full mx-2">
&lt;
</button>
<p className="text-gray-500 text-lg">01/03</p>
<button 
onClick={() => handlePageChange('next')}
className="bg-blue-900 text-gray px-4 py-2 rounded-full mx-2">
&gt;
</button>
      </div>
      </div>
    )}
  </div>
</div>
</div>
    </div>
  )
}
