import React from 'react';

const ProgressLearning = () => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4 mt-16">
      <h2 className="text-xl font-semibold mb-8">Progress Learning</h2>
      
      <ul>
        <li className="flex items-center mb-4">
          <div className="bg-gray-200 rounded-md p-2 mr-2">
            <p className="w-6 h-6 text-gray-600">UI</p>
          </div>
          <div className="flex-grow">
            <span className='font-bold'>Part Typographie</span> {/* Utilisation de font-bold */}
            <br />
            <span className="text-gray-500">User Interface</span>
          </div>
          <div className="ml-2 relative" style={{ width: '80px', height: '12px' }}>
            <div className="absolute inset-0 bg-yellow-200 rounded"></div>
            <div className="absolute inset-0 bg-yellow-500 rounded" style={{ width: '70%' }}></div> 
          </div>
        </li>

        <li className="flex items-center mb-4">
          <div className="bg-gray-200 rounded-md p-2 mr-2">
            <p className="w-6 h-6 text-gray-600">DS</p>
          </div>
          <div className="flex-grow">
            <span className='font-bold'>Part Data Structure</span> {/* Utilisation de font-bold */}
            <br />
            <span className="text-gray-500">science</span>
          </div>
          <div className="ml-2 relative" style={{ width: '80px', height: '12px' }}>
            <div className="absolute inset-0 bg-green-200 rounded"></div>
            <div className="absolute inset-0 bg-green-500 rounded" style={{ width: '50%' }}></div> 
          </div>
        </li>

        <li className="flex items-center mb-4">
          <div className="bg-gray-200 rounded-md p-2 mr-2">
            <p className="w-6 h-6 text-gray-600">AS</p>
          </div>
          <div className="flex-grow">
            <span className='font-bold'>Part Architecture</span> {/* Utilisation de font-bold */}
            <br />
            <span className="text-gray-500">science</span>
          </div>
          <div className="ml-2 relative" style={{ width: '80px', height: '12px' }}>
            <div className="absolute inset-0 bg-red-200 rounded"></div>
            <div className="absolute inset-0 bg-red-500 rounded" style={{ width: '30%' }}></div> 
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ProgressLearning;
