import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import chapters from '../../data/chapter';
import { ToastContainer, toast } from 'react-toastify';
import CallCenterIcon from "../images/call-center-assistant-svgrepo-com.svg"
import AvatarFemme from "../images/user_848006.png"
import 'react-toastify/dist/ReactToastify.css';
import { ChevronDown } from 'lucide-react';
import { Bell } from 'lucide-react';



//const API_BASE_URL = "http://127.0.0.1:3002/api/v1/quiz";
const API_BASE_URL_PROD = "https://api-aviation.technology.dirkk.tech/api/v1/quiz";



export default function SidebarLeftTestPage() {
    const [showRoseDiv, setShowRoseDiv] = useState(false);
    //const [isOpen, setIsOpen] = useState(false);
    //const [score] = useState(82);
    // const [isOperationSelected, setIsOperationSelected] = useState(false);
    const [isContainerExpanded, setIsContainerExpanded] = useState(false);
    const [searchContainerWidth, setSearchContainerWidth] = useState('w-0');
    const [showText, setShowText] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = 3; // Nombre total de pages
    const [recentQuizzes, setRecentQuizzes] = useState([]);
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("id");
    const [error, setError] = useState('');
    const [topic, setTopic] = useState('');
    const [type, setType] = useState('');
    const [chapterSelected, setChapterSelected] = useState('');
    const [searchData, setSearchData] = useState([]);

    useEffect(() => {
        const fetchRecentQuizzes = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL_PROD}/quizzes/recent/${userId}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                });
                setRecentQuizzes(response.data.value);
            } catch (error) {
                setError('Erreur lors de la récupération des réponses.', error);
                //res.status(500).json({message: error});
                //console.error('Erreur lors de la récupération des réponses', error);
            }
        };

        fetchRecentQuizzes();
    }, [token, userId]);

    const handleSearchClick = async () => {
        try {
            // Construction des paramètres de requête
            const params = {
                topic,
                type,
                chapter: chapterSelected,
            };

            // Envoi de la requête GET avec Axios
            const response = await axios.get(`${API_BASE_URL_PROD}/questions/search`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                params: params, // Ajout des paramètres à la requête
            });

            // Vérification de la réponse
            if (response.status !== 200) {
                throw new Error("Failed to retrieve search results");
            }

            // Mise à jour de l'état avec les données reçues
            setSearchData(response.data.limitedResults);
            setSearchContainerWidth(isContainerExpanded ? 'w-0' : 'w-3/4');
            setIsContainerExpanded(!isContainerExpanded);
            setShowText(!showText);
        } catch (error) {
            // Gestion des erreurs
            setError('Erreur lors de la récupération des réponses.');
            console.error('Erreur lors de la récupération des réponses:', error);
        }

        // Logique supplémentaire pour `selectedOption`
        // if (selectedOption === "option1" || selectedOption === "option2") {
        //     setSearchContainerWidth(isContainerExpanded ? 'w-0' : 'w-3/4');
        //     setIsContainerExpanded(!isContainerExpanded);
        //     setShowText(!showText);

        //     if (selectedOption === "option2") {
        //         setShowRoseDiv(true);
        //     }
        // }
    };


    const handlePageChange = (direction) => {
        if (direction === 'next' && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        } else if (direction === 'previous' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="bg-gray-100 pt-10 rounded-lg w-full max-w-lg sm:max-w-md lg:max-w-xs mx-auto transition-all duration-300 ease-in-out shadow-lg">
                <div className="flex justify-between items-center px-4 sm:px-6">
                    <button className="text-white px-4 py-2 rounded-lg">
                        <img src={CallCenterIcon} alt="call center" className="w-6" />
                    </button>
                    <button className="text-white px-4 py-2 rounded-lg">
                        <Bell color="#C7C7C7" />
                    </button>
                    <button className="border w-10 h-10 flex justify-center items-center bg-white text-white rounded-full">
                        <img className="w-8 h-8" src={AvatarFemme} alt="next" />
                    </button>
                </div>

                <h2 className="text-xl sm:text-2xl font-medium text-gray-900 text-center mt-6 mb-4">
                    Search Question
                </h2>   

            <div className="space-y-4 px-4 sm:px-6 mb-6">
                <div className="relative w-full">
                    <select className="appearance-none w-full bg-white border border-white rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 transition" onChange={(e) => setTopic(e.target.value)}>
                        <option value="">Syllabus</option>
                        <option value="090-communications">Communications</option>
                        <option value="021-airframe-systems-electrics-power-plant">Airframe, Systems, Electrics, Power Plant</option>
                        <option value="010-air-law">Air Law</option>
                        <option value="031-mass-balance">Mass & Balance</option>
                        <option value="032-performance">Performance</option>
                        <option value="033-flight-planning-monitoring">Flight Planning Monitoring</option>
                        <option value="040-human-performance-limitations">Human Performance Limitations</option>
                        <option value="050-meteorology">Meteorology</option>
                        <option value="061-general-navigation">General Navigation</option>
                        <option value="062-radio-navigation">Radio Navigation</option>
                        <option value="070-operational-procedures">Operational Procedures</option>
                        <option value="081-principles-of-flight">Principles of Flight</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <ChevronDown className="h-4 w-4" />
                    </div>
                </div>
                <div className="relative w-full">
                    <select className="appearance-none w-full bg-white border border-white rounded py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500" onChange={(e) => setChapterSelected(e.target.value)}>
                        <option value="">Chapters</option>
                        {chapters.map((topic, idxTopic) => (
                            <optgroup key={idxTopic} label={topic.topic}>
                                {topic.chapters.map((chapter, idxChapter) => (
                                    <option key={idxChapter} value={chapter.slug}>
                                        {chapter.title}
                                    </option>
                                ))}
                            </optgroup>
                        ))}

                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <ChevronDown className="h-4 w-4" />
                    </div>
                </div>
                <div className="relative w-full">
                    <select className="appearance-none w-full bg-white border border-white rounded py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500" onChange={(e) => setType(e.target.value)}>
                        <option value="questions">Question</option>
                        <option value="answers">Answer</option>
                        <option value="questionsWithAnswers">Questions and Answers</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <ChevronDown className="h-4 w-4" />
                    </div>
                </div>
                <div className="bg-white flex items-center border border-white rounded-lg overflow-hidden w-full mt-4 shadow-sm">
                    <button className="px-3 text-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-4.35-4.35M16.95 11A6 6 0 1 0 5 11a6 6 0 0 0 11.95 0z" />
                        </svg>
                    </button>
                    <input
                        type="text"
                        placeholder="Words"
                        className="w-full px-3 py-2 focus:outline-none"
                    />
                </div>

                <button
                    onClick={handleSearchClick}
                    className="w-full bg-blue-900 text-white py-2 rounded-lg hover:bg-blue-800 transition-colors duration-200 shadow-md"
                >
                    Search
                </button>
            </div>
            <h3 className="text-lg sm:text-xl text-gray-900 text-center mt-6 font-medium">
                Last Saved Tests
            </h3>
            <div className="space-y-4 mt-4 px-4 sm:px-6 text-center">
                {recentQuizzes.map((quiz, idx) => (
                    <p
                        key={idx}
                        className="text-gray-700 hover:bg-indigo-200 rounded-lg py-2 px-3"
                    >
                        {/*Score: ${quiz.score}/${quiz.totalQuestions}*/}{`${quiz.slugTopic.split("-").slice(1).join(" ")} - ${new Date(quiz.lastUpdated).toLocaleString("fr-FR", { day: "2-digit", month: "2-digit", hour: "2-digit", minute: "2-digit"})}`}
                    </p>
                ))}
                {error && <p className="mt-4 text-red-500">{error}</p>}

                <button className="w-full bg-blue-900 text-white py-2 rounded-lg hover:bg-blue-800 focus:outline-none shadow-md transition-colors duration-200 mt-6">
                    More
                </button>
                {/* <button onClick={handleSearchClick} className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-700 focus:outline-none shadow-md transition-colors duration-200 mt-6">Search</button> */}

                {/*} <h3 className="text-xl text-gray-900 text-center mt-8 font-medium">Last Saved Tests</h3>

                <div className="space-y-4 mt-4 text-center">
                    {["Air Law 5/10 - 22:53", "Meteorology 13/01 - 13:10", "Communication 08/01 - 08:05", "Air Law 11/01 - 16:37"].map((test, idx) => (
                        <p key={idx} className="text-gray-700">{test}</p>
                    ))}
                </div>*/}
                {/* <h3 className="text-xl text-gray-900 text-center mt-8 font-medium">Last Saved Tests</h3> */}
                {/* <div className="space-y-4 mt-4 text-center">
                    {recentQuizzes.map((quiz, idx) => (
                        <p key={idx} className="text-gray-700 hover:bg-red-300">
                            {`Topic: ${quiz.slugTopic} - Score: ${quiz.score}/${quiz.totalQuestions} - ${new Date(quiz.lastUpdated).toLocaleString()}`}
                        </p>
                    ))}
                    {error && <p className="mt-4 text-red-500">{error}</p>}
                </div>


                <button className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-700 focus:outline-none shadow-md transition-colors duration-200 mt-6">More</button> */}
            </div>

            <div className={`bg-gray-100 ${searchContainerWidth} fixed top-0 right-0 bottom-0 text-white pt-2 rounded-lg transition-all duration-300`}>
                <div className="flex flex-col items-center mx-4 my-8 space-y-4">
                    <button onClick={handleSearchClick} className={`bg-blue-900 text-white ${!isContainerExpanded ? "hidden":""} font-bold py-2 px-4 rounded-full`}>Close</button>
                    {showText && (
                        <>
                            {searchData && (
                                <div>
                                    <p className="text-gray-800 text-2xl text-center mb-4">Searched Questions</p>
                                    {searchData.map((question, idx) => (
                                        <button key={idx} className="bg-white text-black py-4 px-4 rounded-lg mb-3 w-full shadow-sm">{question.question}</button>
                                    ))}
                                </div>
                            )}
                            {selectedOption === 'option2' && (
                                <div>
                                    <p className="text-gray-800 text-2xl text-center mb-4">Last Saved Tests</p>
                                    {["Test 1", "Test 2", "Test 3"].map((test, idx) => (
                                        <button key={idx} className="bg-white text-black py-3 px-4 rounded-lg mb-3 w-full shadow-sm">{test}</button>
                                    ))}
                                </div>
                            )}
                            <div className="flex justify-center items-center mt-4">
                                <button onClick={() => handlePageChange('previous')} className="bg-blue-900 text-white px-4 py-2 rounded-full mx-2">&lt;</button>
                                <p className="text-gray-700 text-lg">Page {currentPage}/{totalPages}</p>
                                <button onClick={() => handlePageChange('next')} className="bg-blue-900 text-white px-4 py-2 rounded-full mx-2">&gt;</button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>

    );
}
